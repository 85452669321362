import { auth } from '@osrdata/app_core'
import '@preact/signals-react/auto'
import { terms } from 'assets/terms'
import {
  Loader,
  ModalWrapper,
  ToastMessage,
  TopBar,
  modalSignal,
} from 'components'
import {
  DeniedPage, HomePage, ReplayPage, SelectModulesPage, SelectSubmodulesPage, StatsPage,
} from 'pages'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { getIHMs } from 'reducers/app/thunks'
import { routes, useRoles } from 'services'
import { useAppDispatch, useAppSelector } from 'utils'
import { AvailableSubmodules } from 'types'

import 'moment/locale/fr'

import 'App.scss'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // force hiding modal on route change
    modalSignal.value = undefined
  }, [location.pathname])

  return (
    <Routes>
      <Route path={routes.home} element={<HomePage />} />
      <Route path={routes.moduleSelection()} element={<SelectModulesPage />} />
      <Route path={routes.submoduleSelection()} element={<SelectSubmodulesPage />} />
      <Route path={routes.module()(AvailableSubmodules.replay)} element={<ReplayPage />} />
      <Route path={routes.module()(AvailableSubmodules.cdvStats)} element={<StatsPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, isLoading } = useAppSelector(state => state.user)
  const { hasAccess, rolesLoaded } = useRoles()
  const { loadingIHM } = useAppSelector(state => state.app)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (rolesLoaded && isLogged) {
      if (!hasAccess) {
        setAccessDenied(true)
      } else if (loadingIHM) {
        dispatch(getIHMs())
      }
    }
  }, [isLogged, isLoading, hasAccess, rolesLoaded])

  const renderApp = () => {
    if (isLoading || loadingIHM) return <Loader message={terms.Common.loading} standalone />
    if (accessDenied) return <DeniedPage />

    return (
      <>
        <Router />
        <ModalWrapper />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {!isLoading && <TopBar appName="PAA" />}
        <div id="app">{renderApp()}</div>
        <ToastMessage />
      </BrowserRouter>
    </Suspense>
  )
}
