import terms from 'assets/terms'
import { ModuleSelect } from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import { routes, useBreadcrumb } from 'services'
import { AvailableSubmodules } from 'types'

export default function SelectSubmodulesPage() {
  useBreadcrumb()
  const navigate = useNavigate()
  const { ihmSlug, moduleSlug } = useParams()

  const handleSelect = (submodule: string) => () => {
    navigate(routes.module(ihmSlug, moduleSlug)(submodule))
  }

  return (
    <div id="submoduleSelectPage" className="page">
      <ModuleSelect
        title={terms.Selections.ModuleChoice.title}
        description={terms.Selections.ModuleChoice.description}
        data={[
          {
            id: AvailableSubmodules.replay,
            name: terms.Selections.ModuleChoice.replay,
            inConstruction: false,
          },
          {
            id: AvailableSubmodules.cdvStats,
            name: terms.Selections.ModuleChoice.cdvStats,
            inConstruction: false,
          },
          {
            id: AvailableSubmodules.volume,
            name: terms.Selections.ModuleChoice.volume,
            inConstruction: true,
          },
        ]}
        buttonText={terms.Common.continue}
        handleSelect={handleSelect}
      />
    </div>
  )
}
