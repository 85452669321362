/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { signal } from '@preact/signals-react'
import { TOOL_PAN, UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import { ReactSvgPanZoomLoader } from 'react-svg-pan-zoom-loader'
import moment from 'moment'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import { GATEWAY_DOMAIN } from 'config/config'
import terms from 'assets/terms'
import { AvailableSubmodules, PatchMessage, WsMessage } from 'types'
import { useBreadcrumb } from 'services'
import { useAppDispatch, useAppSelector, catchable } from 'utils'
import { postIHMDate } from 'reducers/app/thunks'
import { Loader, ToastLevel, ToastSignal, Period } from 'components'
import PeriodIndicator from './PeriodIndicator'
import TimeTools from './TimeTools'

import './ModuleReplayPage.scss'

/**
 * Signal to control replay status and message to send to ws
 */
export const ReplaySignal = {
  status: signal<'idle' | 'play' | 'pause' | 'error'>('idle'),
  message: signal<WsMessage>(null),
}

export default function ModuleReplayPage() {
  useBreadcrumb(AvailableSubmodules.replay)
  const userId = useAppSelector(state => state.user.account.id)

  const rq = new RequestManager()
  const dispatch = useAppDispatch()
  const { ihmSlug } = useParams()
  const ws = useRef<WebSocket>()
  const pageWrapperRef = useRef<HTMLDivElement>(null)
  const [wsData, setWsData] = useState<string>('')
  const [svgString, setSvgString] = useState<string>('')
  const [loadingSvg, setLoadingSvg] = useState(true)
  const [clientSize, setClientSize] = useState({ width: 0, height: 0 })
  const { dates_rejeu } = useAppSelector(
    state => state.app.ihms.find(({ site_slug }) => site_slug === ihmSlug),
  )
  const { start_time } = dates_rejeu || {}

  const getSiteSvg = async (site: string) => {
    await catchable(async () => {
      setSvgString('')
      setSvgString(await rq.get(`/paa_mng/svg/${site}/`))
    }, { loadingSetter: setLoadingSvg })
  }

  const startStream = (site: string) => {
    ReplaySignal.status.value = 'play'

    ws.current = new WebSocket(`wss://${GATEWAY_DOMAIN.url}/paa_mng/data_stream/${site}/?_auth_token=${
      `${localStorage.getItem('access_token')}`}`)

    ws.current.onerror = error => {
      console.error('WebSocket Error:', error)
    }

    ws.current.onopen = () => {
      console.log('WebSocket Connected')
      ws.current.send(JSON.stringify({ DEBUG: true, user_id: userId }))
      ws.current.send(JSON.stringify({
        message_type: 'JUMP_TO_TIMESTAMP',
        timestamp: moment(start_time).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      } as WsMessage))
    }

    ws.current.onmessage = event => {
      setWsData(event.data)
    }
  }

  const handleSelectPeriod = (date: Date, duration: number) => {
    const startTimeStr = date ? moment(date).utcOffset(0, true).format() : null
    dispatch(postIHMDate({ slug: ihmSlug, date: { start_time: startTimeStr, duration } }))
  }

  // Get base svg on mount if start_time is set
  useEffect(() => {
    if (!start_time) return

    ws.current?.close()
    getSiteSvg(ihmSlug)

    return () => {
      rq.abort()
    }
  }, [start_time])

  // Start ws stream on svgString change
  useEffect(() => {
    if (!svgString) return

    startStream(ihmSlug)

    return () => {
      ws.current?.close()
    }
  }, [svgString])

  // Apply patch data to svg on wsData change
  useEffect(() => {
    if (!wsData) return
    try {
      const data: PatchMessage = JSON.parse(wsData)
      Object.keys(data.patch).forEach(id => {
        Object.keys(data.patch[id]).forEach(attr => {
          const element = document?.querySelector(`[id='${id}']`)
          if (!element) return
          if (attr === 'inner_html') {
            element.innerHTML = data.patch[id][attr]
            return
          }
          element.setAttribute(attr, data.patch[id][attr])
        })
      })
    } catch (e) {
      ToastSignal.value = { message: 'Error parsing patch data', severity: ToastLevel.ERROR }
      console.error('Error parsing patch data : ', wsData)
      console.error(e)
    }
  }, [wsData])

  // Listen for replay signal to send message to ws
  useEffect(() => {
    if (!ReplaySignal.message.value) return
    ws.current?.send(JSON.stringify(ReplaySignal.message.value))
  }, [ReplaySignal.message.value])

  // Obeserve for pageWrapper resize to update clientSize
  useEffect(() => {
    const element = pageWrapperRef.current
    if (!element) return

    const observer = new ResizeObserver(entries => {
      setClientSize({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      })
    })

    observer.observe(element)

    return () => element && observer.unobserve(element)
  }, [pageWrapperRef?.current])

  if (!start_time) {
    return (
      <Period
        title={terms.Selections.Period.title}
        availableData={terms.Selections.Period.availableData}
        description={terms.Selections.Period.PeriodReplay.description}
        startTimeLabel={terms.Selections.Period.startTime}
        durationLabel={terms.Selections.Period.PeriodReplay.durationLabel}
        buttonText={terms.Selections.Period.PeriodReplay.buttonText}
        onSelect={handleSelectPeriod}
      />
    )
  }

  return (
    <div ref={pageWrapperRef} className="module-page">
      <PeriodIndicator />
      <TimeTools />
      <div className="itineraries">
        <p>Itinéraires en Cde</p>
        <ul>
          <li>87832</li>
          <li>09032</li>
        </ul>
      </div>
      {loadingSvg && <Loader standalone />}
      {pageWrapperRef?.current && (
        <ReactSvgPanZoomLoader
          svgXML={svgString}
          render={content => (
            <UncontrolledReactSVGPanZoom
              width={clientSize.width}
              height={clientSize.height}
              detectAutoPan={false}
              tool={TOOL_PAN}
            >
              <svg
                width={clientSize.width}
                height={clientSize.height}
                fill="rgb(159, 159, 159)"
              >
                {content}
              </svg>
            </UncontrolledReactSVGPanZoom>
          )}
        />
      )}
    </div>
  )
}
